
import { defineComponent } from 'vue'
import RepositoryFactory from "@/lib/https/repositoryFactory";
import AuthRepository from "@/lib/https/authRepository";

export default defineComponent({
    setup() {
        
    },

    data() {
        return {
            fullname: "",
            email: "",
            phone: "",
            content: "",
        }
    },

    methods: {

        checkPhone() {
            var numberformat = /^[0-9]+$/;
            if (this.phone) {
                if (this.phone.match(numberformat))
                    return (true);
                else {
                    return (false);
                }   
            }  
            else 
                return (false);
        },

        checkMail() {
            //eslint-disable-next-line
            var mailformat =  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
           
            if (this.email) {
                if (this.email.match(mailformat))
                    return (true);
                else {
                    return (false);
                }    
            }
            else
                return (false);
        },

        ValidateEmail() {
            //eslint-disable-next-line
            var mailformat =  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            var numberformat = /^[0-9]+$/;

            if (this.fullname == "" || this.content == "") {
                alert("You have entered an invalid name and content !")
                return (false)
            }

            if(this.phone && this.email) {
                if(this.email.match(mailformat) && this.phone.match(numberformat)) {
                    return true;
                }
                else {
                    alert("You have entered an invalid email address or phone number!")
                }
            } else {
                if(this.checkPhone()) {
                    return true;
                } else {
                    if(this.checkMail()) {
                        return true;
                    } else {
                        alert("You have entered an invalid email address or phone number!")
                        return false;
                    }
                }
            }
        },

        async SubmitContact() {
            console.log("submit")
            const checkInput = this.ValidateEmail()

            if (checkInput) {
                const { contact } = RepositoryFactory.getRepository<AuthRepository>(AuthRepository);
                const form = new FormData();
                
                form.append("name", this.fullname);
                form.append("phone", this.phone);
                form.append("mail", this.email);
                form.append("content", this.content);
                const res = await contact(form);
                if (res) {
                    this.$router.push('/vi')
                    alert("Thank for Contacting us!")
                }
            }
        }
        
    }
})
